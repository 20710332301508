import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"

import IndexPageTemplate from "./IndexPageTemplate"

const IndexPage = ({ data }) => {
  const { frontmatter: fm } = data.markdownRemark

  // featured infos
  const { edges: infos } = data.allMarkdownRemark

  return (
    <>
      <MyHelmet title={fm.title} description={fm.subheading} />
      <IndexPageTemplate
        heading={fm.heading}
        subheading={fm.subheading}
        image={fm.image}
        infos={infos}
        about={fm.about}
      />
    </>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 1216, placeholder: BLURRED)
          }
        }
        heading
        subheading
        about {
          heading
          description {
            html
          }
          image: imageAlt {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 1216, placeholder: BLURRED)
              }
            }
          }
          button {
            label
            url
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: { infoCurrent: { eq: true } }
        frontmatter: {
          templateKey: { eq: "info-page" }
          featured: { eq: true }
        }
      }
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "DD. MMMM YYYY", locale: "de-DE")
            icon
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 640
                  placeholder: BLURRED
                  aspectRatio: 1.5
                )
              }
            }
          }
        }
      }
    }
  }
`
