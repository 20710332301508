import React from "react"
import { Link } from "gatsby"

export const PrimaryButton = ({ children, className, ...rest }) => {
  return (
    <Link
      {...rest}
      className={`inline-flex items-center justify-center px-5 py-3 text-base font-medium transition-colors duration-100 rounded-md text-primary-100 bg-primary-700 hover:bg-primary-600 hover:text-white ${className}`}
    >
      {children}
    </Link>
  )
}

export const SecondaryButton = ({ children, className, ...rest }) => {
  return (
    <Link
      {...rest}
      className={`inline-flex items-center justify-center px-5 py-3 text-base font-medium transition-colors duration-100 rounded-md text-primary-700 bg-white dark:text-white dark:bg-gray-500 shadow hover:bg-primary-100 dark:hover:bg-gray-400 ${className}`}
    >
      {children}
    </Link>
  )
}
